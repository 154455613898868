import http from '@/http/axios';
import common from './common';

async function getReportsApi(tenantId, start, end) {
  let query = '';
  if (tenantId) {
    query = `tenantId=${tenantId}`;
  }
  const res = await http.get(`/api/Reports/Tenant?start=${start}&end=${end}&${query}`);
  return common.haveSuccess(res);
}

export default {
  getReportsApi,
};
