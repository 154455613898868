<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-container fiuld>
      <div class="d-flex">
        <label class="mt-5">tenantId：</label>
        <v-select
          v-model="tenantId"
          :items="tenantNameList"
        ></v-select>
      </div>
      <dateTime :title="'日期'" :showTime="false" :showbtn="true" @search="search"></dateTime>
      <v-row>
        <v-col class="d-flex align-center justify-center">
          <half-circle-spinner
            v-if="loading"
            :animation-duration="1000"
            :size="60"
            :color="'#ff1d5e'"
          />
          <Chart :chart-data="datacollection" :option="options" style="width: 500px"
            v-if="datacollection.labels.length > 0 && !loading" />
          <div class="text-h3" style="font-weight: 600" v-else-if="!loading && datacollection.labels.length === 0">
            暂无数据
          </div>
        </v-col>
      </v-row>
      <canvas id="myChart"></canvas>
    </v-container>
  </v-container>
</template>

<script>
import dateTime from '@/components/dateTime.vue';
import Chart from '@/components/Chart.vue';
import Reports from '@/apis/Reports';
import Tenants from '@/apis/Tenants';

export default {
  components: {
    dateTime,
  },
  name: 'reportTableView',
  components: {
    Chart,
  },
  data: () => ({
    canvas: null,
    loading: true,
    tenantId: '所有',
    tenantNameList: ['所有'],
    tenantIdList: ['所有'],
    startDate: '',
    endDate: '',
    reportData: [],
    datacollection: {
      labels: [],
      datasets: [
        {
          label: 'settleAmount',
          data: [],
          backgroundColor: '#58BA6D',
        },
      ],
    },
    options: {},
    tenantsListData: [],
  }),

  created() {
    this.getTenantsList();
    this.options = {
      scales: {
        A: {
          type: 'linear',
          display: true,
          position: 'left',
        },
        B: {
          type: 'linear',
          display: true,
          position: 'right',
        },
      }
    };
  },

  methods: {
    async getTenantsList() {
      const res = await Tenants.getTenantsApi();
      if (res.data.error === 'success') {
        this.tenantsListData = res.data.result;
        this.tenantsListData.forEach((value) => {
          this.tenantNameList.push(value.displayName);
          this.tenantIdList.push(value.id);
        });
      }
    },
    paintCanvas() {
      var Charts = require('chart.js');
      var ctx = document.getElementById('myChart');
      if (this.canvas) {
        this.canvas.destroy();
      }
      this.canvas = new Charts(ctx, {
          type: 'bar',
          data: {
              labels: this.datacollection.labels,
              datasets: [
                {
                  label: '跑量',
                  data: this.datacollection.datasets[0].data,
                  backgroundColor: '#58BA6D',
                  yAxisID: 'A',
                  order: 2,
                },
                {
                  type: 'line',
                  label: '累积跑量',
                  data: this.datacollection.datasets[1].data,
                  backgroundColor: '#0080ff',
                  fill: false,
                  yAxisID: 'B',
                  order: 1,
                }
              ]
          },
          options: {
              scales: {
                  yAxes: [{
                    id: 'A',
                    type: 'linear',
                    position: 'left',
                  }, {
                    id: 'B',
                    type: 'linear',
                    position: 'right',
                    display: false,
                  }]
              }
          }
      });
    },
    async search(value) {
      this.loading = true;
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      const id = this.tenantId === '所有' ? '' : this.tenantIdList[this.tenantNameList.indexOf(this.tenantId)];
      const result = await this.getReportData(id, this.startDate, this.endDate);
      if (result) {
        result.forEach((item, index) => {
          let i = 0;
          result[index].settleAmountTotal = 0;
          while (i <= index) {
            result[index].settleAmountTotal += Number(result[i].settleAmount);
            i += 1;
          }
        });
        await this.reviseData(result);
      }
      this.loading = await false;
    },
    async getReportData() {
      const id = this.tenantId === '所有' ? '' : this.tenantIdList[this.tenantNameList.indexOf(this.tenantId)];
      const res = await Reports.getReportsApi(id, this.startDate, this.endDate);
      this.reportData = res.data.result;
      return res.data.result;
    },
    reviseData(result) {
      this.datacollection = {
        labels: [],
        datasets: [
          {
            type: 'line',
            label: 'settleAmount',
            data: [],
            backgroundColor: '#58BA6D',
          },
          {
            type: 'line',
            label: 'settleAmountTotal',
            data: [],
            backgroundColor: '#58BA6D',
            fill: false,
          },
        ]
      };
      result.forEach((item) => {
        this.datacollection.labels.push(item.date);
        this.datacollection.datasets[0].data.push(item.settleAmount);
        this.datacollection.datasets[1].data.push(item.settleAmountTotal);
      });
      this.paintCanvas();
      this.$forceUpdate();
    },
  },
};
</script>
